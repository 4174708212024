import styled from 'styled-components';

const PageHeader = styled.div`
  background:  linear-gradient(rgba(0,0,0,0.15),rgba(0,0,0,0.4)), url("/media/RodeBessen.jpg") center top no-repeat;
  background-size: cover;
  background-position: center 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: -140px;
  padding-top: 200px;
  padding-bottom: 100px;
  text-align: center;
  color: #fff;

  & p {
    text-transform: capitalize;
    font-size: var(--size-400);
  }

  @media screen and (max-width: 700px) {
    & h1 {
      font-size: var(--size-700);
    }
  }
`;

export default PageHeader;
