import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from './container';
import styled from 'styled-components';
import SocialLinks from './social-links';

const Footer = () => {
  const data = useStaticQuery(
    graphql`
    {
      contacts: allMarkdownRemark(filter: {fields: {contentType: {eq: "contacts"}}}, limit: 9) {
        nodes {
          fields {
            slug
            contentType
          }
          frontmatter {
            name
            title
            description
            address
            phone
            mail
            bank
          }
        }
      }
    }
    `
  );

  let contactOrder = ['chris', 'hedaloc', 'gertjan'];
  let contactName = (contact) => contact.fields.slug.match('\/contacts\/(.*)\/')[1];
  let sortedContacts = data.contacts.nodes.sort((a, b) => contactOrder.indexOf(contactName(a)) > contactOrder.indexOf(contactName(b)));
  const Contacts = sortedContacts.map(({ frontmatter, fields }) => {
    const {name, title, description, address, phone, mail, bank} = frontmatter;
    const { slug } = fields;
    return (
      <ContactInfo
        key={slug}
        name={name}
        title={title}
        description={description}
        address={address}
        phone={phone}
        mail={mail}
        bank={bank}
      />
    );
  });

  return (
    <StyledFooter>
      <FooterWrapper>
      {Contacts}
      </FooterWrapper>
      <FooterWrapper>
        <SocialLinks />
        <p>
          COPYRIGHT © 2007 - 2022 <strong>HEDALOC BVBA</strong>
        </p>
        <FooterAttribution>
          <a href='#' rel="nofollow">▲</a>
        </FooterAttribution>
      </FooterWrapper>
    </StyledFooter>
  );
};

export default Footer;

const ContactInfo = ({name, title, description, phone, mail, bank, address}) => {
  return (
    <StyledContactInfo>
      <h3>{name}</h3>
      {title && (<p>{title}</p>)}
      {description && (<p>{description}</p>)}
      {address && address.map((part, i) => (<p key={i}>{part}</p>))}
      {phone && (<p>Tel. {phone}</p>)}
      {mail && (<p><strong>{mail}</strong></p>)}
      {bank && (<p>BTW. {bank}</p>)}
    </StyledContactInfo>
  );
};

const StyledContactInfo = styled.div`
  min-width: 200px;
  @media screen and (max-width: 700px) {
    margin-bottom: var(--size-300);
  }
  & h3 {
    margin-bottom: var(--size-300);
  }
`;

const StyledFooter = styled.footer`
  padding-top: var(--size-300);
  padding-bottom: var(--size-300);
`;

const FooterAttribution = styled.p`
  /* font-size: var(--size-300); */

  & a {
    color: inherit;
  }
`;

const FooterWrapper = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 700px) {
    & {
      flex-direction: column;
      justify-content: center;
      text-align: center
    }
  }
`;
